import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { customerResource } from 'core';
import security from '@concerto-home/security';
import { useSetSharedState } from 'states';
import { handleRegistrationValidated } from 'data-layer';
import SimulationsAPI from 'features/dashboard/services/simulationsAPI';

export default function VerifyAccount(): React.ReactElement {
  const { token } = useParams();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const setUser = useSetSharedState('security:user');
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof token !== 'string') {
      setError('Lien expiré. Veuillez vous recréer un compte');
      return;
    }

    customerResource
      .verifyAccount(token)
      // eslint-disable-next-line
      .then((data: any) => {
        if(data.success) {
          security.authenticate(data.uuid, data.access_token, data.source);
          customerResource.read(String(security.user)).then((u) => {
            setUser(u);
            setSuccess(true);
            handleRegistrationValidated(u);

            let urlRedirection = '/dashboard';

            if (u.source === 'concerto-primes') {
              SimulationsAPI.getSimulations('simulation')
                  .then((res) => {
                    const simulations = res;

                    // si simulations n'est pas vide, on redirige vers la première simulation
                    if (simulations.length > 0) {
                      const simulationId = simulations[0].id;
                      urlRedirection = `/simulation-prime/resultat/${simulationId}`;
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
            }

            setTimeout(() => navigate(urlRedirection), 2000);
          });
        }
        else {
          setError('Lien expiré.')
        }
      })
        .catch(() => setError('Lien expiré.'));
  }, []);

  const title = error || (success ? 'Vérification réussie !' : 'Vérification de votre compte...');
  const subtitle = success ? 'Redirection vers votre compte...' : [error ? 'Veuillez vous recréer un compte' : 'Veuillez patienter un instant'];

  return (
    <div className="accountForgetPasswordContainer">
      <div className="accountForgetPassword">
        <div className="accountForgetPasswordPicto">
          <i className={`icon-${success ? 'validate' : [error ? 'error' : 'loading']}`} />
        </div>
        <h1 className="accountForgetPasswordTitle">{title}</h1>
        <p className="accountForgetPasswordInfos">{subtitle}</p>
      </div>
    </div>
  );
}
